<template>
  <v-card style="padding: 10px;background: var(--v-sidebarColorLight-darken2); overflow: hidden;margin-bottom: 8px;margin-top: 0px">
    <!-- Route Audience Query -->
    <v-row dense>
      <v-col style="min-width:0px">
        <v-select v-model="routeRequest" :items="routeRequests" :item-text="getRouteRequestLabel" offset-y
          :item-value="item => item" label="Route Audience Query" hide-details icon="mdi-filter" outlined dense auto
          @click.stop />
      </v-col>
      <v-col v-if="$store.state.Permissions.routeTargetEdit" cols="auto">
        <v-menu 
          offset-y 
          :close-on-content-click="false"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              style="width:40px !important; height: 40px; "
              small
              dark 
              v-bind="attrs" 
              v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card class="menu-card">
            <v-row dense>
              <v-col>
                <v-row dense>
                  <!-- Route Versions Dropdown -->
                   <v-col cols="4">
                    <v-autocomplete
                      v-model="routeRequestForm.routeVersionId"
                      style="border-bottom-right-radius: 0px; border-top-right-radius: 0px;"
                      :items="routeVersions"
                      :item-text="(item) => 'v' + item.routeReleaseId + '.' + item.routeReleaseRevision + ' [' + item.routeAlgorithmVersion + ']'"
                      item-value="id"
                      label="Route Version"
                      hide-details
                      outlined
                      dense
                      autocomplete
                      :filter="customFilter"
                    />
                   </v-col>
                   <!-- Granularity Dropdown -->
                   <v-col cols="4">
                    <v-autocomplete
                      v-model="routeRequestForm.routeGranularityAudienceId"
                      style="border-radius: 0px;"
                      label="Granularity"
                      :items="granularityAudiences.data"
                      item-text="description"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                      autocomplete
                      :filter="customFilter"
                    />
                   </v-col>
                   <!-- Demographics Dropdown -->
                   <v-col cols="4">
                    <v-autocomplete
                      v-model="routeRequestForm.routeDemographicId"
                      style="border-bottom-left-radius: 0px; border-top-left-radius: 0px;"
                      label="Demographic"
                      :items="demographics.data"
                      item-text="description"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                      autocomplete
                      :filter="customFilter"
                    />
                   </v-col>
                  </v-row>
              </v-col>
            </v-row>
            <v-row dense class="mt-2">
              <v-col>
                <!-- Custom Query -->
                <v-text-field v-model="routeRequestForm.demographicCustom" label="Custom Query (optional)" hide-details
                  clearable outlined dense />
              </v-col>
              <v-col cols="auto">
                <v-btn @click="addCampaignRouteRequest()" color="primary"
                style="width:40px !important; height: 40px; "
                small>Add</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-progress-linear
    v-show="isLoading"
    style="position: absolute; bottom: 0; left: 0; width: 100%;"
      indeterminate
    ></v-progress-linear>
  </v-card>
</template>

<script>
import RouteController from '@/services/controllers/Route'

export default {
  name: 'AudienceStringSelector',
  
  props: {
    campaignId: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    incomingRouteRequest: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      routeRequest: {},
      routeRequests: [],
      routeVersions: [],
      demographics: { data: [] },
      granularityAudiences: { data: [] },
      routeRequestForm: {
        routeVersionId: null,
        routeDemographicId: null,
        routeGranularityAudienceId: null,
        demographicCustom: '',
      },
    }
  },

  async mounted() {
    console.log("AudienceStringSelector mounted");
  },

  async created() {
    await this.getCampaignRouteRequests()
    await this.getDropdownValues()
  },

  methods: {
    customFilter(item, queryText, itemText) {
      const text = item.description || itemText;
      return text.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    getRouteRequestLabel(routeRequestItem) {
      let routeRequest = this.routeRequests.find(rr => rr.id === routeRequestItem.id)
      if(routeRequest === undefined) {
        return
      }
      let routeVersion = this.routeVersions.find(rv => rv.id === routeRequest.routeVersionId)
      if(routeVersion === undefined) {
        return
      }
      let routeVersionLabel = 'v' + routeVersion.routeReleaseId + '.' + routeVersion.routeReleaseRevision + ' [' + routeVersion.routeAlgorithmVersion + ']'
      let demographic = this.demographics.data.find(d => d.id === routeRequest.routeDemographicId)
      let demographicLabel = demographic ? demographic.description : ''
      let granularity = this.granularityAudiences.data.find(g => g.id === routeRequest.routeGranularityAudienceId)
      let granularityLabel = granularity ? granularity.description : ''
      let demographicCustomLabel = routeRequest.demographicCustom ? " : " + routeRequest.demographicCustom : ''
      return routeVersionLabel + " - " + granularityLabel + ", " + demographicLabel + demographicCustomLabel
    },

    async getCampaignRouteRequests() {
      try {
        const res = await RouteController.getCampaignRouteRequests(this.campaignId)
        this.routeRequests = res.data.map(rr => rr.routeRequest)
        if(this.routeRequests.length > 0) {
          this.routeRequest = this.incomingRouteRequest && this.incomingRouteRequest.id != null ? this.incomingRouteRequest : this.routeRequests[0]
        }
        this.$emit('route-requests-updated', this.routeRequests)
      } catch (err) {
        console.log(err)
      }
    },

    async getDropdownValues() {
      try {
        this.demographics = await RouteController.getDemographics()
        this.granularityAudiences = await RouteController.getGranularityAudiences()
        const rvers = await RouteController.getRouteVersions()
        this.routeVersions = rvers.data
      } catch (err) {
        console.log(err)
      }
    },

    addCampaignRouteRequest() {
      let demographicCustom = this.routeRequestForm.demographicCustom == null ? '' : this.routeRequestForm.demographicCustom

      if(this.routeRequestForm.routeVersionId == null || this.routeRequestForm.routeDemographicId == null || this.routeRequestForm.routeGranularityAudienceId == null)
      {
        this.$root.$emit('snackbarError', 'Please select a Route Version, Demographic and Granularity Audience')
        return
      }
      
      let routeRequestObj = {
        campaignId: this.campaignId,
        routeVersionId: this.routeRequestForm.routeVersionId,
        routeDemographicId: this.routeRequestForm.routeDemographicId,
        routeGranularityAudienceId: this.routeRequestForm.routeGranularityAudienceId,
        demographicCustom: demographicCustom,
      }

      RouteController.addCampaignRouteRequest(routeRequestObj)
        .then((res) => {
          console.log("add route request response", res)
          this.routeRequests.push(res.data)
          this.$root.$emit('snackbarSuccess', 'Route Request Added')
          this.$emit('route-request-added', res.data)
          
          // Reset the form
          this.routeRequestForm = {
            routeVersionId: null,
            routeDemographicId: null,
            routeGranularityAudienceId: null,
            demographicCustom: '',
          }
        })
        .catch((error) => {
          console.log("add route request error message",error)
          this.$root.$emit('snackbarError', 'Error adding Route Request: ' + error.response.data.message)
        })
    },
  },

  watch: {
    incomingRouteRequest(val, oldVal) {
      if(val && oldVal && val.id > 0 && val.id != oldVal.id) {
        this.routeRequest = val
      }
    },
    routeRequest(val, oldVal) {
      if(val && oldVal && val.id > 0 && val.id != oldVal.id) {
        this.$emit('route-request-changed', val)
      }
    }
  }
}
</script>

<style scoped>

.menu-card {
  margin:0px;
  margin-top:5px;
  width: 450px !important;
  padding: 10px;
  background: var(--v-sidebarColorLight-darken1);
  overflow: hidden;
  width: 100%;
}
</style>